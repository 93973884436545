import { useCallback, useEffect, useState } from 'react';
import {
  connectUserToExistingSchoolRecord,
  createSchoolRecordAndConnect,
  getSchoolRecordsByLocation,
} from '../services/SchoolRecordService';
import { Combobox, Transition } from '@headlessui/react';
import { Mixpanel } from '../services/Mixpanel';
import { usePlacesWidget } from 'react-google-autocomplete';
import LoadingSpinner from './LoadingSpinner';
import { debounce } from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const focusOnSchoolNameInput = () => {
  const input = document.getElementById('new-school-name-input');
  if (input) {
    setTimeout(() => {
      input.focus();
      input.setSelectionRange(input.value.length, input.value.length);
    });
  }
};

const validateSchoolInfo = (schoolInfo, country) => {
  const isUSA = country === 'United States';
  // must always have name, enrollment, and city. must have state if USA
  if (!schoolInfo.name || !schoolInfo.city) {
    return false;
  }
  if (isUSA && !schoolInfo.state) {
    return false;
  }
  return true;
};

const API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

export function EnterSchoolInfo({
  onSkipSelectSchool,
  afterSubmitSchool,
  existingSchool,
  setSchoolSelectionFormStep,
  skipSelectSchoolText,
}) {
  const [showExistingSchool, setShowExistingSchool] = useState(false);
  const [city, setCity] = useState();
  const [state, setState] = useState({});
  const [country, setCountry] = useState({});
  const [isLoadingSchools, setIsLoadingSchools] = useState(false); // true when we're fetching schools
  const [loadedSchools, setLoadedSchools] = useState([]);
  const [schoolQuery, setSchoolQuery] = useState(''); // the string they've typed into the search bar
  const [selectedSchool, setSelectedSchool] = useState(); // the actual school they've selected (from our list)
  const [newSchoolInfo, setNewSchoolInfo] = useState({}); // the school info they've entered manually, { name, city, state?, country }
  const [showAddSchoolForm, setShowAddSchoolForm] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [confirmedDistrict, setConfirmedDistrict] = useState(false);
  const [schoolsInSearch, setSchoolsInSearch] = useState([]);
  const { ref: placesRef } = usePlacesWidget({
    apiKey: API_KEY,
    onPlaceSelected: async (place) => {
      if (!place?.address_components) {
        return;
      }
      setIsLoadingSchools(true);
      let googleCity = place.address_components.find((component) =>
        component.types.includes('locality')
      );
      if (!googleCity) {
        googleCity = place.address_components.find((component) =>
          component.types.includes('administrative_area_level_3')
        );
      }
      const googleCountry = place.address_components.find((component) =>
        component.types.includes('country')
      );

      let googleState = {};
      if (googleCountry.long_name === 'United States') {
        googleState = place.address_components.find((component) =>
          component.types.includes('administrative_area_level_1')
        );
      }

      const lat = place.geometry.location.lat();
      const lon = place.geometry.location.lng();

      Mixpanel.track('Place selected from Google Places', {
        city: googleCity,
        state: googleState,
        country: googleCountry,
        lat,
        lon,
      });

      const response = await getSchoolRecordsByLocation({
        city: googleCity.long_name,
        state: googleState.short_name, // this will be undefined in non-US contexts, and that's ok
        country: googleCountry.long_name,
        lat,
        lon,
      });
      setCity(googleCity);
      setState(googleState);
      setCountry(googleCountry);
      setLoadedSchools(response.data.records || []);
      setIsLoadingSchools(false);

      setTimeout(() => {
        const input = document.getElementById('search-input');
        input?.focus();
      }, 100);
    },
  });

  const searchDisabled = isLoadingSchools || !city?.long_name;

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (!query) {
        setShowDropDown(false);
        return;
      }
      if (query.length < 3) {
        setShowDropDown(false);
        return;
      }
      setShowDropDown(true);
      if (!loadedSchools) {
        return;
      }
      const lowerCaseQuery = query.toLowerCase();
      const lowerCaseSchools =
        loadedSchools?.map((school) => ({
          ...school,
          lowerCaseName: school.name.toLowerCase(),
        })) || [];

      // Filter and limit results
      let schools = [];
      for (let i = 0; i < lowerCaseSchools.length && schools.length < 20; i++) {
        if (lowerCaseSchools[i].lowerCaseName.includes(lowerCaseQuery)) {
          schools.push(lowerCaseSchools[i]);
        }
      }

      setSchoolsInSearch(schools);
    }, 50),
    [loadedSchools]
  );

  useEffect(() => {
    debouncedSearch(schoolQuery);
  }, [schoolQuery]);

  const hasConfirmedDistrict = () => {
    if (!selectedSchool) {
      return false;
    }
    if (selectedSchool.is_district) {
      return confirmedDistrict;
    } else {
      return true;
    }
  };

  const confirmButtonEnabled =
    (selectedSchool && hasConfirmedDistrict()) ||
    validateSchoolInfo(newSchoolInfo, country.long_name);

  const toggleAddNewSchoolForm = () => {
    setShowAddSchoolForm((prev) => {
      Mixpanel.track("Don't see your school toggled", {
        willShowAddSchoolForm: !prev,
      });
      const newVal = !prev;
      if (newVal) {
        setTimeout(() => {
          focusOnSchoolNameInput();
        }, 100);
      }
      return newVal;
    });
    setNewSchoolInfo({
      name: schoolQuery,
      city: city.long_name,
      state: state?.short_name || '',
      country: country.long_name,
      is_district: false,
    });
  };

  const onSubmitSchool = async (existingSchool) => {
    Mixpanel.track('School info form submitted', {
      connectToExistingSchool: !!selectedSchool,
      country: country.long_name,
    });

    // if we have a selected school, use that
    let school = selectedSchool || existingSchool;
    if (school) {
      const response = await connectUserToExistingSchoolRecord({
        school_id: school.id,
      });
      if (response?.data?.success) {
        afterSubmitSchool?.(response.data);
      } else {
        console.error('Error connecting user to school', response);
      }
    } else {
      const response = await createSchoolRecordAndConnect({
        ...newSchoolInfo,
        country: country.long_name,
      });
      if (response?.data?.success) {
        afterSubmitSchool?.();
      } else {
        console.error('Error creating school and connecting user', response);
      }
    }
  };

  useEffect(() => {
    if (existingSchool) {
      setShowExistingSchool(true);
    }
  }, [existingSchool]);

  useEffect(() => {
    setSchoolSelectionFormStep(getStep());
  }, [showExistingSchool, showAddSchoolForm]);

  const getStep = () => {
    if (showExistingSchool) {
      return 'existing';
    }
    if (showAddSchoolForm) {
      return 'addSchool';
    }
    return 'search';
  };

  return (
    <div className='flex flex-col items-center text-center gap-y-3 overflow-auto px-1 w-full'>
      {getStep() === 'existing' && (
        <div className='w-full'>
          <p className='text-sm text-gray-700 pb-1'>You are currently teaching at:</p>
          <p className='text-lg font-semibold'>{existingSchool.name}</p>
          <p className='text-sm text-gray-700'>{existingSchool.location_string}</p>
          <div className='flex flex-row w-full space-between pt-4'>
            <button
              className='bg-white hover:bg-gray-100 rounded-md w-full p-2 m-2 text-emerald-600 border border-emerald-600 focus:outline-emerald-500'
              onClick={() => setShowExistingSchool(false)}>
              Edit
            </button>
            <button
              className='w-full bg-emerald-600 text-white rounded-md p-2 m-2 disabled:opacity-50'
              onClick={() => {
                onSubmitSchool(existingSchool);
              }}>
              Continue
            </button>
          </div>
        </div>
      )}
      {getStep() === 'addSchool' && (
        <div className={`w-full`}>
          <AddNewSchoolForm
            newSchoolInfo={newSchoolInfo}
            setNewSchoolInfo={setNewSchoolInfo}
            country={country}
          />
          <div className='flex flex-row w-full space-between'>
            <button
              className='w-full m-1 text-sm font-medium text-emerald-600 flex flex-row flex-wrap content-center'
              onClick={() => setShowAddSchoolForm(false)}>
              <ChevronLeftIcon className='w-4 h-4 self-center' />
              Back to school search
            </button>
            <button
              className='w-full bg-emerald-600 text-white rounded-md p-2 m-2 disabled:opacity-50'
              onClick={() => onSubmitSchool()}
              disabled={!confirmButtonEnabled}>
              Continue
            </button>
          </div>
        </div>
      )}
      <div className={`${getStep() !== 'search' ? 'hidden' : ''} w-full`}>
        <div className='w-full flex gap-x-3 text-gray-800 text-sm'>
          <div className={`w-full`}>
            <label htmlFor='city-input' className={`block text-left font-semibold`}>
              Location
            </label>
            <input
              ref={placesRef}
              onChange={() => {
                setSchoolQuery('');
                setSelectedSchool();
                setNewSchoolInfo({});
                if (showAddSchoolForm) {
                  toggleAddNewSchoolForm();
                }
              }}
              autoComplete='off'
              id='city-input'
              className={`h-[40px] border rounded-md w-full focus:border-emerald-500 focus:ring-emerald-500 border-gray-300 text-gray-800 placeholder:text-sm placeholder:text-gray-300`}
              type='text'
              placeholder='City, State, Country'
            />
          </div>
        </div>

        <Combobox
          className='w-full text-sm mt-3'
          as='div'
          aria-labelledby='search'
          value={schoolQuery}>
          <Combobox.Label
            className={`relative block text-left font-medium ${searchDisabled ? 'text-gray-400' : ''}`}>
            School or District Name
          </Combobox.Label>

          <>
            <div className='relative'>
              <div className='absolute inset-y-0 right-0 flex items-center pr-3'>
                {isLoadingSchools && (
                  <LoadingSpinner className={`h-5 w-5 text-emerald-500`} aria-hidden='true' />
                )}
              </div>
              <Combobox.Input
                id='search-input'
                className={`w-full height-10 sm:text-sm sm:leading-6 rounded-md border ring-0 focus:border-emerald-500 focus:ring-emerald-500 ${
                  searchDisabled
                    ? 'placeholder-gray-200 border-gray-300'
                    : 'placeholder-gray-400 border-gray-400'
                }`}
                onFocus={(e) => {
                  searchDisabled && e.target.blur();
                }}
                placeholder={searchDisabled && !isLoadingSchools ? '' : 'Search for your school'}
                disabled={searchDisabled}
                value={schoolQuery}
                onChange={(e) => {
                  if (showAddSchoolForm) {
                    toggleAddNewSchoolForm();
                  }
                  setSchoolQuery(e.target.value);
                  setSelectedSchool();
                  setNewSchoolInfo({});
                }}
                onBlur={(e) => {
                  if (e.target.id !== 'search-input') {
                    Mixpanel.track('School search input blurred without selecting', {
                      searchQuery: schoolQuery,
                    });
                  }
                }}
              />
            </div>
          </>
          <Transition
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            afterLeave={() => {}}>
            <Combobox.Options
              id='list-of-options'
              className='absolute bg-white w-[90%] z-[999999] max-h-[200px] border rounded-md border-gray-500 empty:hidden overflow-auto'>
              {showDropDown && schoolsInSearch.length === 0 && (
                <Combobox.Option
                  value=''
                  className='group flex cursor-default items-center gap-2 py-1.5 px-3'>
                  <div id='school-option-button' className='w-full text-left py-2'>
                    No matches
                  </div>
                </Combobox.Option>
              )}
              {showDropDown &&
                schoolsInSearch.map((school) => (
                  <Combobox.Option
                    key={school.id}
                    value={school.name}
                    className='group flex cursor-default items-center gap-2 hover:bg-emerald-100 '>
                    <button
                      id='school-option-button'
                      onClick={(e) => {
                        Mixpanel.track('School selected from search', {
                          school: school.name,
                        });

                        setSchoolQuery(school.name);
                        setSelectedSchool(school);
                        setTimeout(() => {
                          const input = document.getElementById('search-input');
                          input.blur();
                        }, 0);
                      }}
                      className='w-full text-left py-3.5 px-3'>
                      {school.name}
                    </button>
                  </Combobox.Option>
                ))}
              {showDropDown && (
                <>
                  <hr className='mx-4' />
                  <Combobox.Option className='group flex cursor-default items-center gap-2 hover:bg-gray-100'>
                    <button
                      onClick={() => {
                        Mixpanel.track('Add school or district selected from search', {
                          query: schoolQuery,
                        });
                        if (!showAddSchoolForm) {
                          toggleAddNewSchoolForm();
                        } else {
                          focusOnSchoolNameInput();
                        }
                      }}
                      className='w-full text-left py-3.5 px-3 text-emerald-600 flex flex-row'>
                      Add your school
                      <ChevronRightIcon className='w-4 h-4 self-center' />
                    </button>
                  </Combobox.Option>
                </>
              )}
            </Combobox.Options>
          </Transition>
          <p
            className={`text-xs text-left text-gray-400 ${!searchDisabled || isLoadingSchools ? 'invisible' : ''}`}>
            Enter a city to search schools
          </p>
        </Combobox>
        {selectedSchool && selectedSchool.is_district && (
          <div className='bg-gray-50 p-3 border rounded-md'>
            <div className='flex flex-row'>
              <input
                id='confirm-district-checkbox'
                type='checkbox'
                value={confirmedDistrict}
                onChange={(e) => setConfirmedDistrict(e.target.checked)}
                className='p-2 mt-1 border-2 border-emerald-600 rounded-sm focus:border-emerald-500 focus:ring-emerald-500 text-emerald-600 text-sm placeholder-gray-400 align-left'
              />
              <div className='flex flex-col px-2'>
                <p className='text-left'>
                  I work at the district office, or across multiple schools
                </p>
                <p className='text-left text-gray-500 text-sm'>
                  If not, please select a school above
                </p>
              </div>
            </div>
          </div>
        )}

        <div className='flex flex-row w-full space-between pt-2'>
          <button
            className='w-full m-2 text-sm font-medium text-emerald-600'
            onClick={onSkipSelectSchool}>
            {skipSelectSchoolText}
          </button>
          <button
            className='w-full bg-emerald-600 text-white rounded-md p-2 m-2 disabled:opacity-50'
            onClick={() => onSubmitSchool()}
            disabled={!confirmButtonEnabled}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

const AddNewSchoolForm = ({ newSchoolInfo, setNewSchoolInfo, country }) => {
  return (
    <div className='flex flex-col w-full gap-y-3 mb-5'>
      <div>
        <FormInput
          value={newSchoolInfo.name || ''}
          onChange={(e) => {
            setNewSchoolInfo((prev) => ({
              ...prev,
              name: e.target.value,
            }));
          }}
          label='School Name'
          id='new-school-name-input'
          type='text'
          placeholder="Enter your school's name"
        />
      </div>

      <div>
        <FormInput
          label='City'
          id='city-input-new-form'
          type='text'
          placeholder='Enter your city'
          value={newSchoolInfo.city || ''}
          onChange={(e) => {
            setNewSchoolInfo((prev) => ({
              ...prev,
              city: e.target.value,
            }));
          }}
        />
      </div>
      <div className={`${country.long_name !== 'United States' ? 'hidden' : ''}`}>
        <FormInput
          label='State'
          id='state-input'
          type='text'
          placeholder='Enter your state'
          value={newSchoolInfo.state || ''}
          onChange={(e) => {
            setNewSchoolInfo((prev) => ({
              ...prev,
              state: e.target.value,
            }));
          }}
        />
      </div>
      <div className={`${country.long_name === 'United States' ? 'hidden' : ''}`}>
        <FormInput
          label='Country'
          id='country-input'
          type='text'
          placeholder='Enter your state'
          value={newSchoolInfo.country || ''}
          onChange={(e) => {
            setNewSchoolInfo((prev) => ({
              ...prev,
              country: e.target.value,
            }));
          }}
        />
      </div>
    </div>
  );
};

const FormInput = ({ label, id, type, placeholder, onChange, value }) => {
  return (
    <>
      <label htmlFor={id} className='block text-left font-semibold text-sm'>
        {label}
      </label>
      <input
        id={id}
        value={value}
        className='w-full p-2 border rounded-md border-gray-300 focus:border-emerald-500 focus:ring-emerald-500 text-gray-800 text-sm placeholder-gray-400'
        type={type}
        placeholder={placeholder}
        onChange={onChange}
      />
    </>
  );
};
